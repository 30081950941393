import React, { useState, useEffect } from "react";
import Navbar from "../../pages/Navbar";
import * as firebase from "firebase/app";
import "./styles.css";
import { Spinner } from "react-activity";
import Alterar from "../Alterar";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
export default function Consulta({ history }) {
  const [nSei, setNSei] = useState("");
  const [dSpinner, setDSpinner] = useState("container");
  const [dDetails, setDDetails] = useState("container-results");
  const [carregar, setCarregar] = useState(false);
  const [respoEnt, setRespoEnt] = useState({
    nSei: " "
  });
  const [respoEq, setRespoEq] = useState([]);

  async function buscarEquipamento() {
    setDDetails("container-results d-none ");
    var arrayResults = [];
    await firebase
      .firestore()
      .collection("products")
      .where("ref", "==", nSei)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          arrayResults.push(doc.data());
        });
        setDDetails("container-results");
        setDSpinner("container d-none");
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
        setDDetails("container-results");
        setDSpinner("container d-none");
      });
    setRespoEq(arrayResults);
    console.log(arrayResults);
  }
  async function buscar() {
    setDDetails("container-results d-none ");
    var arrayResults = "";
    await firebase
      .firestore()
      .collection("entidades")
      .where("ref", "==", nSei)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          arrayResults = doc.data();
        });
        setDDetails("container-results");
        setDSpinner("container d-none");
        buscarEquipamento();
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
        setDDetails("container-results");
        setDSpinner("container d-none");
      });
    setRespoEnt(arrayResults);
    console.log(arrayResults);
  }

  useEffect(() => {
    setDDetails("container-results d-none ");
    var respo = localStorage.getItem("ref");
    setNSei(respo);
    console.log(nSei);
    buscar();
  }, [carregar]);

  useEffect(() => {
    setDDetails("container-results d-none ");
    var respo = localStorage.getItem("ref");
    setNSei(respo);
    console.log("carregando...");
    setCarregar(true);
  }, []);

  async function Alterar(ref, uid) {
    var arrayResults = "";
    await firebase
      .firestore()
      .collection("products")
      .where("ref", "==", ref)
      .where("uid", "==", uid)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          arrayResults = doc.data();
        });
        localStorage.setItem("alterar", JSON.stringify(arrayResults));
        history.push("/alterar");
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }
  async function AlterarEntidade(ref) {
    var arrayResults = "";
    await firebase
      .firestore()
      .collection("entidades")
      .where("ref", "==", ref)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          arrayResults = doc.data();
        });
        localStorage.setItem("alterarentidade", JSON.stringify(arrayResults));
        history.push("/alterarentidade");
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  function perguntar(ref) {
    confirmAlert({
      title: "Atenção!",
      message:
        "Se você excluir a entidade, os equipamentos relacionados a mesma serão excluidos automaticamente. Deseja Excluir?",
      buttons: [
        {
          label: "Sim",
          onClick: () => ExcluirEntidade(ref)
        },
        {
          label: "Não",
          onClick: () => history.push("/home")
        }
      ]
    });
  }

  function copiar(ref) {
    const texto = ref;
    //Cria um elemento input (pode ser um textarea)
    let inputTest = document.createElement("input");
    inputTest.value = texto;
    //Anexa o elemento ao body
    document.body.appendChild(inputTest);
    //seleciona todo o texto do elemento
    inputTest.select();
    //executa o comando copy
    //aqui é feito o ato de copiar para a area de trabalho com base na seleção
    document.execCommand("copy");
    //remove o elemento
    document.body.removeChild(inputTest);
    toast.notify("ID copiado para Área de Transferência", {
      position: "top-right",
      tye: "success"
    });
  }

  async function ExcluirEntidade(ref) {
    history.push("/home");
    await firebase
      .firestore()
      .collection("entidades")
      .doc(ref)
      .delete()
      .then(function () {
        firebase
          .firestore()
          .collection("products")
          .where("ref", "==", ref)
          .get()
          .then(querySnapshot => {
            toast.notify("Dados excluidos com sucesso!", {
              position: "top-right",
              tye: "success"
            });
            querySnapshot.forEach(doc => {
              firebase
                .firestore()
                .collection("products")
                .doc(doc.data().uid)
                .delete()
                .then(function () { })
                .catch(function (error) {

                });
            });
          })
          .catch(function (error) {
            toast.notify("Não foi possível excluir os dados!", {
              position: "top-right",
              tye: "success"
            });
          });
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  }
  return (
    <>
      <Navbar class={dDetails} />

      <div
        className={dSpinner}
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "300px"
        }}
      >
        <Spinner size={100} color="#fff" />
      </div>

      <div class={dDetails}>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-end">
            <p>
              <strong>Dados do COBA</strong>
            </p>
          </div>
          <div class="btn-group dropleft" role="group">
            <button
              id="btnGroupDrop1"
              type="button"
              class="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {" "}
              Opções
            </button>
            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <a
                style={{ cursor: "pointer" }}
                class="dropdown-item"
                onClick={() => {
                  AlterarEntidade(respoEnt.ref);
                }}
              >
                Alterar Dados da Entidade{" "}
              </a>
              <a
                class="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  perguntar(respoEnt.ref);
                }}
              >
                Excluir Entidade
              </a>
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.print();
                }}
              >
                Imprimir
              </a>
            </div>
          </div>
        </div>
        <div id="detalhes">
          <div class="dropdown-divider"></div>
          <div class="row">
            <div class="col-sm">
              ID DE REGISTRO <br />{" "}
              <p
                onClick={() => {
                  copiar(respoEnt.ref);
                }}
                style={{ cursor: "pointer", color: "#0099e6" }}
                data-toggle="tooltip"
                data-placement="right"
                title="Clique para copiar o ID para a Área de Transferência."
              >
                {" "}
                {respoEnt.ref}
              </p>{" "}
              <br />
            </div>
          </div>
          <p>
            <strong>Dados do Processo</strong>
          </p>
          <div class="dropdown-divider"></div>
          <div class="row">
            <div class="col-sm">
              Nº SEI <br /> {respoEnt.nSei ? `${respoEnt.nSei}` : '***************'} <br />
              <br />
            </div>
            <div class="col-sm">
              Nº PROCESSO JUDICIAL <br /> {respoEnt.pJudicial ? `${respoEnt.pJudicial}` : '***************'} <br />
              <br />
            </div>
          </div>
          <p>
            <strong>Dados da Entidade</strong>
          </p>
          <div class="dropdown-divider"></div>
          <div class="row">
            <div class="col-sm">
              NOME FANTASIA <br /> {respoEnt.nomeFantasia ? `${respoEnt.nomeFantasia}` : '***************'} <br />
              <br />
              {/*}ENDERECO <br /> {props.enderecoEntidade}<br /><br />
                                CEP <br /> {props.cepEntidade}<br /><br />{*/}
            </div>
            <div class="col-sm">
              RAZÃO SOCIAL: <br /> {respoEnt.razaoSocial ? `${respoEnt.razaoSocial}` : '***************'}
              <br />
              <br />
              {/*}CIDADE: <br /> {props.cidadeEntidade}<br /><br />{*/}
            </div>
            <div class="col-sm">
              CNPJ: <br /> {respoEnt.cnpj ? `${respoEnt.cnpj}` : '***************'} <br />
              <br />
              {/*}ESTADO: <br /> {props.estadoEntidade}<br /><br />{*/}
            </div>
          </div>
          <div class="row">
            <div class="col-sm">
              ENDERECO <br />
              {respoEnt.enderecoEntidade ? `${respoEnt.enderecoEntidade}` : '***************'} <br />
              <br />
            </div>
            <div class="col-sm">
              CIDADE: <br />
              {respoEnt.cidadeEntidade ? `${respoEnt.cidadeEntidade}` : '***************'} <br />
              <br />
            </div>
            <div class="col-sm">
              ESTADO: <br />
              {respoEnt.estadoEntidade ? `${respoEnt.estadoEntidade}` : '***************'} <br />
              <br />
            </div>
          </div>
          <div class="row">
            <div class="col-sm">
              CEP <br />
              {respoEnt.cepEntidade ? `${respoEnt.cepEntidade}` : '***************'} <br />
              <br />
            </div>
          </div>

          <p>
            <strong>Dados do Responsável</strong>
          </p>
          <div class="dropdown-divider"></div>
          <div class="row">
            <div class="col-sm">
              NOME <br />
              {respoEnt.nomeCompleto ? `${respoEnt.nomeCompleto}` : '***************'} <br />
              <br />
            </div>
            <div class="col-sm">
              VÍNCULO: <br /> {respoEnt.vinculo ? `${respoEnt.vinculo}` : '***************'}
              <br />
              <br />
            </div>
            <div class="col-sm">
              CPF: <br /> {respoEnt.cpf ? `${respoEnt.cpf}` : '***************'}
              <br />
              <br />
            </div>


          </div>
          <div class="row">
            <div class="col-sm">
              RG: <br /> {respoEnt.rg ? `${respoEnt.rg}` : '***************'}

              {respoEnt.orgaoExpedidorRg ? ` | ${respoEnt.orgaoExpedidorRg}` : ''}
              {respoEnt.ufOrgaoExpedidorRg? ` - ${respoEnt.ufOrgaoExpedidorRg}` : ''}
              
              <br />
              <br />
            </div>
          </div>
          <div class="row">
            <div class="col-sm">
              ENDERECO <br />
              {respoEnt.endereco ? `${respoEnt.endereco}` : '***************'} <br />
              <br />
            </div>
            <div class="col-sm">
              CIDADE: <br />
              {respoEnt.cidade ? `${respoEnt.cidade}` : '***************'} <br />
              <br />
            </div>
            <div class="col-sm">
              ESTADO: <br />
              {respoEnt.estado ? `${respoEnt.estado}` : '***************'} <br />
              <br />
            </div>
          </div>
          <div class="row">
            <div class="col-sm">
              CEP <br />
              {respoEnt.cep ? `${respoEnt.cep}` : '***************'} <br />
              <br />
            </div>
          </div>
          <div class="row">
            <div class="col-sm">
              TELEFONE <br />
              {respoEnt.telefone ? `${respoEnt.telefone}` : '***************'} <br />
              <br />
            </div>
            <div class="col-sm">
              CELULAR: <br />
              {respoEnt.celular ? `${respoEnt.celular}` : '***************'} <br />
              <br />
            </div>
            <div class="col-sm"></div>
          </div>
        </div>
        <p>
          <strong>Equipamentos apreendidos</strong>
        </p>
        <div class="dropdown-divider"></div>
        <table class="table  table-hover">
          <thead>
            <tr>
              <th scope="col">Lacre</th>
              <th scope="col">Auto de Infração</th>
              <th scope="col">Tipo</th>
              <th scope="col">Sala</th>
              <th scope="col">Coluna</th>
            </tr>
          </thead>
          <tbody>
            {respoEq.map(value => (
              <tr
                key={value.uid}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  Alterar(value.ref, value.uid);
                }}
              >
                <td>{value.lacre ? `${value.lacre}` : '***************'}</td>
                <td>{value.auto ? `${value.auto}` : '***************'}</td>
                <td>{value.tipo ? `${value.tipo}` : '***************'}</td>
                <td>{value.sala ? `${value.sala}` : '***************'}</td>
                <td>{value.coluna ? `${value.coluna}` : '***************'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
