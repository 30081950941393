 import firebase from 'firebase'
 var config = {
    apiKey: "AIzaSyA-Pw1Ycr85SyRQkXoSd23JoKS_7OjbSR8",
    authDomain: "cobaanatel.firebaseapp.com",
    databaseURL: "https://cobaanatel.firebaseio.com",
    projectId: "cobaanatel",
    storageBucket: "cobaanatel.appspot.com",
    messagingSenderId: "350898375949",
    appId: "1:350898375949:web:76e64af8cf900a9a3ef444"
  };
  // Initialize Firebase
  firebase.initializeApp(config).firestore();
 
  
  export default config; 