import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { Button, Spinner, Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import toast from "toasted-notes";
import firebase from "firebase/app";
import QRCode from "qrcode.react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function Alterar({ history }) {
  const [dataEntidade, setDataEntidade] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [inputValid, setInputValid] = useState("form-control is-invalid");
  const [displaySmall, setDisplaySmall] = useState("d-none");
  const [disabledInput, setDisabledInput] = useState(true);
  const [animation, setAnimation] = useState("");
  const [disabledForm, setDisabledForm] = useState("");
  const [enablePrint, setEnablePrint] = useState("d-none");
  const [dataAtual, setDataAtual] = useState("");
  const [situations, setSituations] = useState([
    " ",
    "Apreendido",
    "Destruição",
    "Destinação",
    "Devolução",
    "Doação",
    "Perdimento",
  ]);
  const [inputSituation, setInputSituation] = useState("");
  const [inputFabricante, setInputFabricante] = useState("");
  const [inputModelo, setInputModelo] = useState("");
  const [inputNHomologacao, setInputNHomologacao] = useState("");

  // Dados do formulario

  const [inputLacre, setInputLacre] = useState("");
  const [inputTipo, setInputTipo] = useState("");
  const [inputAuto, setInputAuto] = useState("");
  const [inputDataAuto, setInputDataAuto] = useState("");
  const [inputSala, setInputSala] = useState("");
  const [inputColuna, setInputColuna] = useState("");
  const [inputObs, setInputObs] = useState("");
  const [optionSalas, setOptionSalas] = useState("");
  const [optionEq, setOptionEq] = useState("");
  const [inputPotencia, setInputPotencia] = useState("");
  const [inputSerie, setInputSerie] = useState("");

  const [inputHomologado, setInputHomologado] = useState("");
  // Dados alteração

  const [confirmaHomologacao, setConfirmarHomologacao] = useState([
    "INDEFINIDO",
    "SIM",
    "NAO",
  ]);
  const [dados, setDados] = useState("");

  useEffect(() => {
    const respo = JSON.parse(localStorage.getItem("alterar"));
    console.log(respo);
    setDados(respo);
    setInputSearch(respo.ref);
    setInputLacre(respo.lacre);
    setInputTipo(respo.tipo);
    setInputAuto(respo.auto);
    setInputDataAuto(respo.dataAuto);
    setInputSala(respo.sala);
    setInputColuna(respo.coluna);
    setInputObs(respo.obs);
    setOptionEq([respo.tipo]);
    setOptionSalas([respo.sala]);

    respo.nHomologacao === undefined
      ? setInputNHomologacao("")
      : setInputNHomologacao(respo.nHomologacao);

    respo.modelo === undefined
      ? setInputModelo("")
      : setInputModelo(respo.modelo);

    respo.fabricante === undefined
      ? setInputFabricante("")
      : setInputFabricante(respo.fabricante);
    respo.situacao === undefined
      ? setInputSituation("")
      : setInputSituation(respo.situacao);

    respo.potencia === undefined
      ? setInputPotencia("")
      : setInputPotencia(respo.potencia);

    respo.serie === undefined ? setInputSerie("") : setInputSerie(respo.serie);

    respo.homologado === undefined
      ? setInputHomologado("")
      : setInputHomologado(respo.homologado);
  }, []);
  useEffect(() => {
    firebase
      .firestore()
      .collection("salas")
      .orderBy("nome")
      .get()
      .then(function (querySnapshot) {
        var array = [];
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          array.push(doc.data());
        });
        setOptionSalas(array);
        console.log(array);
      });
    firebase
      .firestore()
      .collection("tiposeq")
      .orderBy("nome")
      .get()
      .then(function (querySnapshot) {
        var arrayEq = [];
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          arrayEq.push(doc.data());
        });
        setOptionEq(arrayEq);
        console.log(arrayEq);
      });
  }, []);

  function alterar() {
    searchOption();
    setDisabledInput(false);
  }
  function perguntar() {
    confirmAlert({
      title: "Atenção!",
      message: "Deseja realmente excluir esse equipamento da base de dados?",
      buttons: [
        {
          label: "Sim",
          onClick: () => deleteProducts(),
        },
        {
          label: "Não",
          onClick: () => history.push("/home"),
        },
      ],
    });
  }
  async function createProduct(event) {
    event.preventDefault();
    setDisabledInput(true);
    setAnimation("grow");

    var idUpdate = firebase.firestore().collection("products").doc(dados.uid);

    // Set the "capital" field of the city 'DC'
    return idUpdate
      .update({
        nSei: dados.nSei.toUpperCase().trim(),
        lacre: inputLacre.toUpperCase().trim(),
        tipo: inputTipo.toUpperCase().trim(),
        auto: inputAuto.toUpperCase().trim(),
        dataAuto: inputDataAuto.trim(),
        sala: inputSala.toUpperCase().trim(),
        coluna: inputColuna.toUpperCase().trim(),
        dataEntrada: dataAtual.trim(),
        dataSaida: false,
        obs: inputObs.trim(),
        nHomologacao: inputNHomologacao.toLocaleUpperCase().trim(),
        modelo: inputModelo.toLocaleUpperCase().trim(),
        fabricante: inputFabricante.toUpperCase().trim(),
        situacao: inputSituation.toUpperCase().trim(),
        potencia: inputPotencia.toUpperCase().trim(),
        serie: inputSerie.toUpperCase().trim(),
        homologado: inputHomologado.toUpperCase().trim(),
        consulta: [
          dados.nSei.toUpperCase().trim(),
          inputLacre.toUpperCase().trim(),
          inputTipo.toUpperCase().trim(),
          inputSala.toUpperCase().trim(),
          inputAuto.toUpperCase().trim(),
          inputColuna.toUpperCase().trim(),
          inputSituation.toUpperCase().trim(),
        ],
      })
      .then(function () {
        console.log("Document successfully updated!");
        setDisabledForm("d-none");
        setEnablePrint("");
        if (window.screen.width > 640) {
          window.print();
        }
        setEnablePrint("d-none");
        setInputLacre("");
        setInputTipo("ANTENA");
        setInputSala("A");
        setInputColuna("");
        setInputObs("");
        setDisabledForm("");
        setAnimation("");
        setDisabledInput(false);
        toast.notify("Alterações realizadas com sucesso!", {
          position: "top-right",
        });
        history.push("/home");
        setAnimation("");
        setDisabledInput(true);
      });
  }

  async function deleteProducts() {
    firebase
      .firestore()
      .collection("products")
      .doc(dados.uid)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
        toast.notify("Equipamento excluido da base de dados!", {
          position: "top-right",
        });
        history.push("/home");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  }
  async function searchOption() {
    setDataEntidade("");
    setDisplaySmall("d-none");
    setInputValid("form-control is-invalid");
    setDisabledInput(true);
    await firebase
      .firestore()
      .collection("entidades")
      .where("ref", "==", dados.ref.trim())
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          setInputValid("form-control is-valid");
          setDataEntidade(doc.data());
          setDisplaySmall("");
          setDisabledInput(false);
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  useEffect(() => {
    var data = new Date();
    var dia = data.getDate();
    var mes = data.getMonth();
    var ano4 = data.getFullYear();
    var str_data = dia + "/" + (mes + 1) + "/" + ano4;
    setDataAtual(str_data);
  }, []);

  async function ImprimirEtiqueta() {
    await searchOption();
    setDisabledForm("d-none");
    setEnablePrint("");
    if (window.screen.width > 640) {
      window.print();
    }
    setEnablePrint("d-none");
    setDisabledForm("");
    setDisabledInput(true);
  }
  /* useEffect(() => {
         firebase.firestore().collection("salas").orderBy("nome").get().then(function (querySnapshot) {
             var array = []
             querySnapshot.forEach(function (doc) {
                 // doc.data() is never undefined for query doc snapshots
                 array.push(doc.data())
             });
             setOptionSalas(array)
             console.log(array);
         });
         firebase.firestore().collection("tiposeq").orderBy("nome").get().then(function (querySnapshot) {
             var arrayEq = []
             querySnapshot.forEach(function (doc) {
                 // doc.data() is never undefined for query doc snapshots
                 arrayEq.push(doc.data())
 
             });
             setOptionEq(arrayEq)
             setDisabledInput(false)
             console.log(arrayEq);
         });
     }, [])*/
  return (
    <>
      <Navbar />

      <div id="form-product" class={disabledForm}>
        <form onSubmit={createProduct}>
          <p>
            <strong>Dados da Entidade</strong>
          </p>
          <div className="dropdown-divider"></div>
          <div className="form-group ">
            <label htmlFor="nsei">ID da Entidade *</label>
            <input
              required
              className={inputValid}
              value={inputSearch}
              disabled
              onChange={(event) =>
                setInputSearch(event.target.value.toUpperCase())
              }
            />

            <div className={displaySmall}>
              <small id="emailHelp" className="form-text text-muted">
                <strong>Entidade: </strong>
                {dataEntidade.nomeFantasia}
              </small>
              <small id="emailHelp" className="form-text text-muted">
                <strong>Responsável: </strong>
                {dataEntidade.nomeCompleto}
              </small>
            </div>
          </div>
          <p>
            <strong>Dados do Equipamento</strong>
          </p>
          <div className="dropdown-divider"></div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputTipo">Tipo</label>
              <select
                value={inputTipo}
                disabled={disabledInput ? "disabled" : ""}
                onChange={(event) =>
                  setInputTipo(event.target.value.toUpperCase())
                }
                id="inputTipo"
                className="form-control"
              >
                {disabledInput ? (
                  <option>{inputTipo}</option>
                ) : (
                  optionEq.map((item) => (
                    <option>{item.nome.toUpperCase()}</option>
                  ))
                )}
              </select>
            </div>

            <div className="form-group col-sm-6">
              <label htmlFor="fabricante">Fabricante</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputFabricante}
                onChange={(event) =>
                  setInputFabricante(event.target.value.toUpperCase())
                }
                type="text"
                className="form-control"
                id="fabricante"
              />
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="modelo">Modelo</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputModelo.toUpperCase()}
                onChange={(event) => setInputModelo(event.target.value)}
                type="text"
                className="form-control"
                id="modelo"
              />
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="modelo">Potência</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputPotencia.toUpperCase()}
                onChange={(event) => setInputPotencia(event.target.value)}
                type="text"
                className="form-control"
                id="modelo"
              />
            </div>

            <div className="form-group col-sm-4">
              <label htmlFor="modelo"> Nº de Série</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputSerie.toUpperCase()}
                onChange={(event) => setInputSerie(event.target.value)}
                type="text"
                className="form-control"
                id="modelo"
              />
            </div>
            <div className="form-group col-md-4">
              <label for="inputCity">Homologado</label>
              <select
                required
                disabled={disabledInput ? "disabled" : ""}
                value={inputHomologado.toUpperCase()}
                onChange={(event) => setInputHomologado(event.target.value)}
                id="inputSituation"
                className="form-control"
              >
                {confirmaHomologacao.map((item) => (
                  <option>{item.toLocaleUpperCase()}</option>
                ))}
              </select>
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="nhomologação">Nº de Homologação</label>
              <input
                disabled={
                  disabledInput === true || inputHomologado !== "SIM"
                    ? "disabled"
                    : ""
                }
                value={inputNHomologacao.toUpperCase()}
                onChange={(event) => setInputNHomologacao(event.target.value)}
                type="text"
                className="form-control"
                id="lacre"
                placeholder=""
              />
            </div>
          </div>
          <p>
            <strong>Dados do Auto</strong>
          </p>
          <div className="dropdown-divider"></div>
          <div className="form-row">
            <div className="form-group col-sm-4">
              <label htmlFor="auto">Auto de Infração</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputAuto}
                onChange={(event) =>
                  setInputAuto(event.target.value.toUpperCase())
                }
                type="text"
                className="form-control"
                id="auto"
                placeholder="Auto de Infração"
              />
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="lacre">Lacre</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputLacre}
                onChange={(event) =>
                  setInputLacre(event.target.value.toUpperCase())
                }
                type="text"
                className="form-control"
                id="lacre"
                placeholder="Lacre"
              />
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="date-auto">Data do Auto de Infração</label>
              <InputMask
                disabled={disabledInput ? "disabled" : ""}
                className="form-control"
                mask="99/99/9999"
                value={inputDataAuto.toUpperCase()}
                onChange={(event) => setInputDataAuto(event.target.value)}
                id="date-auto"
              />
            </div>
          </div>
          <p>
            <strong>Dados do Armazenamento</strong>
          </p>
          <div className="dropdown-divider"></div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label for="inputCity">Sala *</label>
              <select
                required
                disabled={disabledInput ? "disabled" : ""}
                value={inputSala}
                onChange={(event) =>
                  setInputSala(event.target.value.toUpperCase())
                }
                id="inputSala"
                className="form-control"
              >
                {disabledInput ? (
                  <option>{inputSala}</option>
                ) : (
                  optionSalas.map((item) => (
                    <option>{item.nome.toUpperCase()}</option>
                  ))
                )}
              </select>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputColuna">Coluna *</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                required
                type="number"
                value={inputColuna}
                onChange={(event) =>
                  setInputColuna(event.target.value.toUpperCase())
                }
                className="form-control"
                id="inputColuna"
              />
            </div>
            <div className="form-group col-md-6">
              <label for="inputCity">Situação</label>
              <select
                required
                disabled={disabledInput ? "disabled" : ""}
                value={inputSituation}
                onChange={(event) =>
                  setInputSituation(event.target.value.toUpperCase())
                }
                id="inputSituation"
                className="form-control"
              >
                {disabledInput ? (
                  <option>{inputSituation}</option>
                ) : (
                  situations.map((item) => (
                    <option>{item.toUpperCase()}</option>
                  ))
                )}
              </select>
            </div>
          </div>
          <div className="form-group ">
            <label htmlFor="obs">Observações: </label>
            <textarea
              disabled={disabledInput ? "disabled" : ""}
              value={inputObs}
              onChange={(event) =>
                setInputObs(event.target.value.toUpperCase())
              }
              class="form-control"
              id="obs"
              rows="3"
            ></textarea>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <Button
                onClick={() => {
                  perguntar();
                }}
                variant="primary"
                className="btn btn-danger"
              >
                Excluir{" "}
                <Spinner
                  as="span"
                  animation={animation}
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>{" "}
              <Button
                onClick={() => {
                  alterar();
                }}
                variant="primary"
                className="btn btn-warning"
              >
                Alterar{" "}
                <Spinner
                  as="span"
                  animation={animation}
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>{" "}
              <Button
                disabled={disabledInput ? "disabled" : ""}
                variant="primary"
                type="submit"
                className="btn btn-success"
              >
                Salvar{" "}
                <Spinner
                  as="span"
                  animation={animation}
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            </div>
            <div>
              <Button
                disabled={disabledInput ? "" : "disabled"}
                variant="primary"
                onClick={() => {
                  ImprimirEtiqueta();
                }}
                className="btn btn-primary d-none d-sm-block"
              >
                Imprimir Etiqueta{" "}
                <Spinner
                  as="span"
                  animation={animation}
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div id="printable" className={enablePrint}>
        <table class="table table-bordered table-dark">
          <thead>
            <tr>
              <th colSpan="7" scope="col">
                {dataEntidade.nomeFantasia} - PROCESSO Nº {inputSearch}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-secondary">
              <th
                style={{
                  width: "10%",
                  margin: "0px auto",
                  float: "none",
                }}
                rowSpan="3"
              >
                <QRCode size={150} value={`${dataEntidade.nomeFantasia}`} />
              </th>
              <th scope="row" style={{ height: "10px" }}>
                NOME
              </th>
              <th>TERMO DE FISCALIZAÇÃO / LAI</th>
              <th>DATA</th>
              <th>CIDADE / UF</th>
              <th>EQUIPAMENTO</th>
              <th>LACRE</th>
            </tr>
            <tr>
              <th scope="row">{dataEntidade.nomeFantasia}</th>
              <td>{inputAuto}</td>
              <td>{inputDataAuto}</td>
              <td>
                {dataEntidade.cidadeEntidade} / {dataEntidade.estadoEntidade}
              </td>
              <td>{inputTipo}</td>
              <td>{inputLacre}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="printable" className={enablePrint}>
        <table class="table table-bordered table-dark">
          <thead>
            <tr>
              <th colSpan="9" scope="col">
                {dataEntidade.nomeFantasia} - PROCESSO Nº {inputSearch}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-secondary">
              <th
                style={{
                  width: "10%",
                  margin: "0px auto",
                  float: "none",
                }}
                rowSpan="3"
              >
                <QRCode size={150} value={`${dataEntidade.nomeFantasia}`} />
              </th>
              <th scope="row" style={{ height: "10px" }}>
                NOME
              </th>
              <th>TERMO DE FISCALIZAÇÃO / LAI</th>
              <th>DATA</th>
              <th>CIDADE / UF</th>
              <th>EQUIPAMENTO</th>
              <th>LACRE</th>
            </tr>
            <tr>
              <th scope="row">{dataEntidade.nomeFantasia}</th>
              <td>{inputAuto}</td>
              <td>{inputDataAuto}</td>
              <td>
                {dataEntidade.cidadeEntidade} / {dataEntidade.estadoEntidade}
              </td>
              <td>{inputTipo}</td>
              <td>{inputLacre}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="printable" className={enablePrint}>
        <table class="table table-bordered table-dark">
          <thead>
            <tr>
              <th colSpan="9" scope="col">
                {dataEntidade.nomeFantasia} - PROCESSO Nº {inputSearch}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-secondary">
              <th
                style={{
                  width: "10%",
                  margin: "0px auto",
                  float: "none",
                }}
                rowSpan="3"
              >
                <QRCode size={150} value={`${dataEntidade.nomeFantasia}`} />
              </th>
              <th scope="row" style={{ height: "10px" }}>
                NOME
              </th>
              <th>TERMO DE FISCALIZAÇÃO / LAI</th>
              <th>DATA</th>
              <th>CIDADE / UF</th>
              <th>EQUIPAMENTO</th>
              <th>LACRE</th>
            </tr>
            <tr>
              <th scope="row">{dataEntidade.nomeFantasia}</th>
              <td>{inputAuto}</td>
              <td>{inputDataAuto}</td>
              <td>
                {dataEntidade.cidadeEntidade} / {dataEntidade.estadoEntidade}
              </td>
              <td>{inputTipo}</td>
              <td>{inputLacre}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="printable" className={enablePrint}>
        <table class="table table-bordered table-dark">
          <thead>
            <tr>
              <th colSpan="9" scope="col">
                {dataEntidade.nomeFantasia} - PROCESSO Nº {inputSearch}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-secondary">
              <th
                style={{
                  width: "10%",
                  margin: "0px auto",
                  float: "none",
                }}
                rowSpan="3"
              >
                <QRCode size={150} value={`${dataEntidade.nomeFantasia}`} />
              </th>
              <th scope="row" style={{ height: "10px" }}>
                NOME
              </th>
              <th>TERMO DE FISCALIZAÇÃO / LAI</th>
              <th>DATA</th>
              <th>CIDADE / UF</th>
              <th>EQUIPAMENTO</th>
              <th>LACRE</th>
            </tr>
            <tr>
              <th scope="row">{dataEntidade.nomeFantasia}</th>
              <td>{inputAuto}</td>
              <td>{inputDataAuto}</td>
              <td>
                {dataEntidade.cidadeEntidade} / {dataEntidade.estadoEntidade}
              </td>
              <td>{inputTipo}</td>
              <td>{inputLacre}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
