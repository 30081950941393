import React, { useState } from 'react';
import './styles.css';
import * as firebase from 'firebase/app'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import logo from '../../images/logo-login.png'
import { Button, Spinner } from 'react-bootstrap'



function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [animation, setAnimation] = useState('')
    const [disabledInput, setDisabledInput] = useState(false)



    async function login(event) {
        setAnimation('grow')
        setDisabledInput(true)
        event.preventDefault()
        await firebase.auth().signInWithEmailAndPassword(email, password).then(function () {
            console.log('usuário logado!')
        }).catch(function (error) {
            // Handle Errors here.
            setAnimation(' ')
            setDisabledInput(false)
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/invalid-email') {
                errorMessage = "O endereço de email é inválido."
            }
            if (errorCode === 'auth/user-not-found') {
                errorMessage = "Não há registro de usuário correspondente a esse identificador. O usuário pode ter sido excluído."
            }
            if (errorCode === 'auth/wrong-password') {
                errorMessage = "A senha é inválida ou o usuário não tem permissões para acessar o sistema."
            }
            if (errorCode === 'auth/network-request-failed') {
                errorMessage = "Ocorreu um erro de rede (como tempo limite, conexão interrompida ou host inacessível)."
            }
            //
            setEmail('')
            setPassword('')
            toast.notify(errorMessage, { position: "top-right", type: 'error' })

        });
    }
    return (
        <>

            <div className="container">

                <form id="caixa-login" onSubmit={login}>
                    <div id="logo">
                        <img src={logo} />
                        <p>Login</p>
                    </div>

                    <div>

                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Endereço de email</label>
                            <input required disabled={disabledInput ? "disabled" : ""} value={email} onChange={event => setEmail(event.target.value)} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Seu email" />
                            <small id="emailHelp" className="form-text text-muted">Entre com o e-mail fornecido pelo administrador do sistema.</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Senha</label>
                            <input required disabled={disabledInput ? "disabled" : ""} value={password} onChange={event => setPassword(event.target.value)} type="password" className="form-control" id="exampleInputPassword1" placeholder="Senha" />
                            <small id="emailHelp" className="form-text text-muted">Sua senha é pessoal e intransferível.</small>
                        </div>
                        <div id='' className="btn-login">
                            <Button variant="primary" type="submit" className="btn btn-primary">
                                Acessar{' '}
                                <Spinner
                                    as="span"
                                    animation={animation}
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>

                        </div>
                    </div>
                    <div className="info">
                        <small id="emailHelp" className="form-text text-muted">Desenvolvido na unidade UO092/PI. </small>
                        <small id="emailHelp" className="form-text text-muted">
                            Desenvolvedor: <a target="_blank" href="https://github.com/tiagopierre">Tiago Pierre de Mendonça</a></small>
                    </div>
                </form>

            </div>
        </>
    );
}

export default Login;
