import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import "./styles.css";
import { Button, Spinner, Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import toast from "toasted-notes";
import firebase from "firebase/app";
import QRCode from "qrcode.react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function CreateProduct({ history }) {
  const [dataEntidade, setDataEntidade] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [inputValid, setInputValid] = useState("form-control is-invalid");
  const [displaySmall, setDisplaySmall] = useState("d-none");
  const [disabledInput, setDisabledInput] = useState(true);
  const [animation, setAnimation] = useState("");
  const [disabledForm, setDisabledForm] = useState("");
  const [enablePrint, setEnablePrint] = useState("d-none");
  const [dataAtual, setDataAtual] = useState("");

  // Dados do formulario

  const [inputLacre, setInputLacre] = useState("");
  const [inputTipo, setInputTipo] = useState("Antena");
  const [inputFabricante, setInputFabricante] = useState("");
  const [inputModelo, setInputModelo] = useState("");
  const [inputNHomologacao, setInputNHomologacao] = useState("");
  const [inputAuto, setInputAuto] = useState("");
  const [inputDataAuto, setInputDataAuto] = useState("");
  const [inputSala, setInputSala] = useState("A");
  const [inputColuna, setInputColuna] = useState("");
  const [inputObs, setInputObs] = useState("");
  const [optionSalas, setOptionSalas] = useState([]);
  const [optionEq, setOptionEq] = useState([]);
  const [inputPotencia, setInputPotencia] = useState("");
  const [inputSerie, setInputSerie] = useState("");
  const [situations, setSituations] = useState([
    "Apreendido",
    "Destruição",
    "Destinação",
    "Devolução",
    "Doação",
    "Perdimento",
  ]);

  const [confirmaHomologacao, setConfirmarHomologacao] = useState([
    "INDEFINIDO",
    "SIM",
    "NAO",
  ]);

  const [inputSituation, setInputSituation] = useState("Apreendido");
  const [inputHomologado, setInputHomologado] = useState("INDEFINIDO");

  function perguntar() {
    confirmAlert({
      title: "Salvo!",
      message: "Deseja relacionar um novo equipamento neste mesmo processo?",
      buttons: [
        {
          label: "Sim",
          onClick: () => console.log("Click Yes"),
        },
        {
          label: "Não",
          onClick: () => history.push("/home"),
        },
      ],
    });
  }
  async function createProduct(event) {
    event.preventDefault();
    setDisabledInput(true);
    setAnimation("grow");

    await firebase
      .firestore()
      .collection("products")
      .add({
        nSei: dataEntidade.nSei,
        ref: inputSearch.trim(),
        lacre: inputLacre.toUpperCase().trim(),
        tipo: inputTipo.toUpperCase().trim(),
        auto: inputAuto.toUpperCase().trim(),
        dataAuto: inputDataAuto.trim(),
        sala: inputSala.toUpperCase().trim(),
        coluna: inputColuna.toUpperCase().trim(),
        dataEntrada: dataAtual.trim(),
        dataSaida: false,
        obs: inputObs.trim(),
        nHomologacao: inputNHomologacao.toLocaleUpperCase().trim(),
        modelo: inputModelo.toLocaleUpperCase().trim(),
        fabricante: inputFabricante.toUpperCase().trim(),
        situacao: inputSituation.toUpperCase().trim(),
        potencia: inputPotencia.toUpperCase().trim(),
        serie: inputSerie.toUpperCase().trim(),
        homologado: inputHomologado.toUpperCase().trim(),
        consulta: [
          inputSearch.trim(),
          inputLacre.toUpperCase().trim(),
          inputTipo.toUpperCase().trim(),
          inputAuto.toUpperCase().trim(),
          inputSala.toUpperCase().trim(),
          inputColuna.toUpperCase().trim(),
          inputSituation.toUpperCase().trim(),
        ],
      })
      .then(async function (docRef) {
        console.log("Document written with ID: ", docRef.id);

        var idUpdate = firebase
          .firestore()
          .collection("products")
          .doc(docRef.id);
        return idUpdate
          .update({
            uid: docRef.id,
          })
          .then(function () {
            setDisabledForm("d-none");
            setEnablePrint("");
            if (window.screen.width > 640) {
              window.print();
            }
            setEnablePrint("d-none");
            setInputLacre("");
            setInputTipo("ANTENA");
            setInputSala("A");
            setInputColuna("");
            setInputObs("");
            setDisabledForm("");
            setAnimation("");
            setDisabledInput(false);
            perguntar();
          });
      })
      .catch(function (error) {
        console.log("Error adding document: ", error);
        setAnimation("");
        setDisabledInput(true);
      });
  }

  async function searchOption() {
    setDataEntidade("");
    setDisplaySmall("d-none");
    setInputValid("form-control is-invalid");
    setDisabledInput(true);

    await firebase
      .firestore()
      .collection("entidades")
      .where("ref", "==", inputSearch.trim())
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          setInputValid("form-control is-valid");
          setDataEntidade(doc.data());
          setDisplaySmall("");
          setDisabledInput(false);
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  useEffect(() => {
    searchOption();
  }, [inputSearch]);

  useEffect(() => {
    var data = new Date();
    var dia = data.getDate();
    var mes = data.getMonth();
    var ano4 = data.getFullYear();
    var str_data = dia + "/" + (mes + 1) + "/" + ano4;
    setDataAtual(str_data);
  }, []);
  useEffect(() => {
    firebase
      .firestore()
      .collection("salas")
      .orderBy("nome")
      .get()
      .then(function (querySnapshot) {
        var array = [];
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          array.push(doc.data());
        });
        setOptionSalas(array);
        console.log(array);
      });
    firebase
      .firestore()
      .collection("tiposeq")
      .orderBy("nome")
      .get()
      .then(function (querySnapshot) {
        var arrayEq = [];
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          arrayEq.push(doc.data());
        });
        setOptionEq(arrayEq);
        setDisabledInput(false);
        console.log(arrayEq);
      });
  }, []);
  return (
    <>
      <Navbar />

      <div id="form-product" class={disabledForm}>
        <form onSubmit={createProduct}>
          <p>
            <strong>Dados da Entidade</strong>
          </p>
          <div className="dropdown-divider"></div>
          <div className="form-group ">
            <label htmlFor="nsei">ID da Entidade *</label>
            <input
              required
              className={inputValid}
              value={inputSearch}
              onChange={(event) => setInputSearch(event.target.value)}
            />

            <div className={displaySmall}>
              <small id="emailHelp" className="form-text text-muted">
                <strong>Entidade: </strong>
                {dataEntidade.nomeFantasia}
              </small>
              <small id="emailHelp" className="form-text text-muted">
                <strong>Responsável: </strong>
                {dataEntidade.nomeCompleto}
              </small>
            </div>
          </div>
          <p>
            <strong>Dados do Equipamento</strong>
          </p>
          <div className="dropdown-divider"></div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputTipo">Tipo</label>
              <select
                value={inputTipo}
                disabled={disabledInput ? "disabled" : ""}
                onChange={(event) => setInputTipo(event.target.value)}
                id="inputTipo"
                className="form-control"
              >
                {optionEq.map((eq) => (
                  <option value={eq.nome.toUpperCase()}>{eq.nome}</option>
                ))}
              </select>
            </div>
            <div className="form-group col-sm-6">
              <label htmlFor="fabricante">Fabricante</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputFabricante.toUpperCase()}
                onChange={(event) => setInputFabricante(event.target.value)}
                type="text"
                className="form-control"
                id="fabricante"
              />
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="modelo">Modelo</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputModelo.toUpperCase()}
                onChange={(event) => setInputModelo(event.target.value)}
                type="text"
                className="form-control"
                id="modelo"
              />
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="modelo">Potência</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputPotencia.toUpperCase()}
                onChange={(event) => setInputPotencia(event.target.value)}
                type="text"
                className="form-control"
                id="modelo"
              />
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="modelo"> Nº de Série</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputSerie.toUpperCase()}
                onChange={(event) => setInputSerie(event.target.value)}
                type="text"
                className="form-control"
                id="modelo"
              />
            </div>
            <div className="form-group col-md-4">
              <label for="inputCity">Homologado</label>
              <select
                required
                disabled={disabledInput ? "disabled" : ""}
                value={inputHomologado.toUpperCase()}
                onChange={(event) => setInputHomologado(event.target.value)}
                id="inputSituation"
                className="form-control"
              >
                {confirmaHomologacao.map((item) => (
                  <option>{item.toLocaleUpperCase()}</option>
                ))}
              </select>
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="nhomologação">Nº de Homologação</label>
              <input
                disabled={
                  disabledInput === true || inputHomologado !== "SIM"
                    ? "disabled"
                    : ""
                }
                value={inputNHomologacao.toUpperCase()}
                onChange={(event) => setInputNHomologacao(event.target.value)}
                type="text"
                className="form-control"
                id="lacre"
                placeholder=""
              />
            </div>
          </div>
          <p>
            <strong>Dados do Auto</strong>
          </p>
          <div className="dropdown-divider"></div>
          <div className="form-row">
            <div className="form-group col-sm-4">
              <label htmlFor="auto">Auto de Infração</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputAuto.toUpperCase()}
                onChange={(event) => setInputAuto(event.target.value)}
                type="text"
                className="form-control"
                id="auto"
                placeholder="Auto de Infração"
              />
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="lacre">Lacre</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={inputLacre.toUpperCase()}
                onChange={(event) => setInputLacre(event.target.value)}
                type="text"
                className="form-control"
                id="lacre"
                placeholder="Lacre"
              />
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="date-auto">Data do Auto de Infração</label>
              <InputMask
                disabled={disabledInput ? "disabled" : ""}
                className="form-control"
                mask="99/99/9999"
                value={inputDataAuto}
                onChange={(event) => setInputDataAuto(event.target.value)}
                id="date-auto"
              />
            </div>
          </div>
          <p>
            <strong>Dados do Armazenamento</strong>
          </p>
          <div className="dropdown-divider"></div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label for="inputCity">Sala *</label>
              <select
                required
                disabled={disabledInput ? "disabled" : ""}
                value={inputSala.toUpperCase()}
                onChange={(event) => setInputSala(event.target.value)}
                id="inputSala"
                className="form-control"
              >
                {optionSalas.map((salas) => (
                  <option>{salas.nome.toUpperCase()}</option>
                ))}
              </select>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputColuna">Coluna *</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                required
                type="number"
                value={inputColuna.toUpperCase()}
                onChange={(event) => setInputColuna(event.target.value)}
                className="form-control"
                id="inputColuna"
              />
            </div>
            <div className="form-group col-md-6">
              <label for="inputCity">Situação</label>
              <select
                required
                disabled={disabledInput ? "disabled" : ""}
                value={inputSituation.toUpperCase()}
                onChange={(event) => setInputSituation(event.target.value)}
                id="inputSituation"
                className="form-control"
              >
                {situations.map((item) => (
                  <option>{item.toLocaleUpperCase()}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group ">
            <label htmlFor="obs">Observações: </label>
            <textarea
              disabled={disabledInput ? "disabled" : ""}
              value={inputObs}
              onChange={(event) => setInputObs(event.target.value)}
              class="form-control"
              id="obs"
              rows="3"
            ></textarea>
          </div>
          <div className="btn-login">
            <Button
              disabled={disabledInput ? "disabled" : ""}
              variant="primary"
              type="submit"
              className="btn btn-primary"
            >
              Cadastrar{" "}
              <Spinner
                as="span"
                animation={animation}
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          </div>
        </form>
      </div>
      <div id="printable" className={enablePrint}>
        <table class="table table-bordered table-dark">
          <thead>
            <tr>
              <th colSpan="7" scope="col">
                {dataEntidade.nomeFantasia} - PROCESSO Nº {dataEntidade.nSei}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-secondary">
              <th
                style={{
                  width: "10%",
                  margin: "0px auto",
                  float: "none",
                }}
                rowSpan="3"
              >
                <QRCode size={150} value={`${dataEntidade.nomeFantasia}`} />
              </th>
              <th scope="row" style={{ height: "10px" }}>
                NOME
              </th>
              <th>TERMO DE FISCALIZAÇÃO / LAI</th>
              <th>DATA</th>
              <th>CIDADE / UF</th>
              <th>EQUIPAMENTO</th>
              <th>LACRE</th>
            </tr>
            <tr>
              <th scope="row">{dataEntidade.nomeFantasia}</th>
              <td>{inputAuto}</td>
              <td>{inputDataAuto}</td>
              <td>
                {dataEntidade.cidadeEntidade} / {dataEntidade.estadoEntidade}
              </td>
              <td>{inputTipo}</td>
              <td>{inputLacre}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="printable" className={enablePrint}>
        <table class="table table-bordered table-dark">
          <thead>
            <tr>
              <th colSpan="9" scope="col">
                {dataEntidade.nomeFantasia} - PROCESSO Nº {dataEntidade.nSei}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-secondary">
              <th
                style={{
                  width: "10%",
                  margin: "0px auto",
                  float: "none",
                }}
                rowSpan="3"
              >
                <QRCode size={150} value={`${dataEntidade.nomeFantasia}`} />
              </th>
              <th scope="row" style={{ height: "10px" }}>
                NOME
              </th>
              <th>TERMO DE FISCALIZAÇÃO / LAI</th>
              <th>DATA</th>
              <th>CIDADE / UF</th>
              <th>EQUIPAMENTO</th>
              <th>LACRE</th>
            </tr>
            <tr>
              <th scope="row">{dataEntidade.nomeFantasia}</th>
              <td>{inputAuto}</td>
              <td>{inputDataAuto}</td>
              <td>
                {dataEntidade.cidadeEntidade} / {dataEntidade.estadoEntidade}
              </td>
              <td>{inputTipo}</td>
              <td>{inputLacre}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="printable" className={enablePrint}>
        <table class="table table-bordered table-dark">
          <thead>
            <tr>
              <th colSpan="9" scope="col">
                {dataEntidade.nomeFantasia} - PROCESSO Nº {dataEntidade.nSei}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-secondary">
              <th
                style={{
                  width: "10%",
                  margin: "0px auto",
                  float: "none",
                }}
                rowSpan="3"
              >
                <QRCode size={150} value={`${dataEntidade.nomeFantasia}`} />
              </th>
              <th scope="row" style={{ height: "10px" }}>
                NOME
              </th>
              <th>TERMO DE FISCALIZAÇÃO / LAI</th>
              <th>DATA</th>
              <th>CIDADE / UF</th>
              <th>EQUIPAMENTO</th>
              <th>LACRE</th>
            </tr>
            <tr>
              <th scope="row">{dataEntidade.nomeFantasia}</th>
              <td>{inputAuto}</td>
              <td>{inputDataAuto}</td>
              <td>
                {dataEntidade.cidadeEntidade} / {dataEntidade.estadoEntidade}
              </td>
              <td>{inputTipo}</td>
              <td>{inputLacre}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="printable" className={enablePrint}>
        <table class="table table-bordered table-dark">
          <thead>
            <tr>
              <th colSpan="9" scope="col">
                {dataEntidade.nomeFantasia} - PROCESSO Nº {dataEntidade.nSei}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-secondary">
              <th
                style={{
                  width: "10%",
                  margin: "0px auto",
                  float: "none",
                }}
                rowSpan="3"
              >
                <QRCode size={150} value={`${dataEntidade.nomeFantasia}`} />
              </th>
              <th scope="row" style={{ height: "10px" }}>
                NOME
              </th>
              <th>TERMO DE FISCALIZAÇÃO / LAI</th>
              <th>DATA</th>
              <th>CIDADE / UF</th>
              <th>EQUIPAMENTO</th>
              <th>LACRE</th>
            </tr>
            <tr>
              <th scope="row">{dataEntidade.nomeFantasia}</th>
              <td>{inputAuto}</td>
              <td>{inputDataAuto}</td>
              <td>
                {dataEntidade.cidadeEntidade} / {dataEntidade.estadoEntidade}
              </td>
              <td>{inputTipo}</td>
              <td>{inputLacre}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
