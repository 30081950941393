import React, { useState, useEffect } from "react";
import Navbar from "../../pages/Navbar";
import Faixa from "../../pages/Faixa";
import "./styles.css";
import upperCase from "upper-case";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default function ResultsEq({ history }) {
  const [dados, setDados] = useState([]);
  const [carregar, setCarregar] = useState(false);
  const [quantResults, setQuantResults] = useState(false);

  useEffect(() => {
    var respo = JSON.parse(localStorage.getItem("results"));
    var respoQuant = JSON.parse(localStorage.getItem("quantResults"));
    setDados(respo);

    if (respoQuant == 0 || respoQuant == 1) {
      setQuantResults(`${respoQuant} registro.`);
    }
    if (respoQuant > 1) {
      setQuantResults(`${respoQuant} registros.`);
    }
  }, [carregar]);

  function mostrar(nSei) {
    localStorage.setItem("ref", nSei);
    history.push("/dados-da-consulta");
  }

  useEffect(() => {
    var respo = JSON.parse(localStorage.getItem("results"));
    var respoQuant = JSON.parse(localStorage.getItem("quantResults"));
    setDados(respo);

    if (respoQuant == 0 || respoQuant == 1) {
      setQuantResults(`${respoQuant} registro.`);
    }
    if (respoQuant > 1) {
      setQuantResults(`${respoQuant} registros.`);
    }
    setCarregar(true);
  }, []);

  return (
    <>
      <Navbar />
      <div className="container-results">
        <h4>Dados da consulta</h4>
        <p>Esta consulta retornou {quantResults}</p>
        <br />
        <div id="table-results" class="table-responsive">
          <table id="table-to-xls" class="table table-bordered">
            <thead class="thead-dark ">
              <tr>
                <th scope="col">NOME FANTASIA</th>
                <th scope="col">Nº SEI</th>
                <th scope="col">LACRE</th>
                <th scope="col">TIPO</th>
                <th scope="col">AUTO DE INFRAÇÃO</th>
                <th scope="col">SALA</th>
                <th scope="col">COLUNA</th>
              </tr>
            </thead>
            <tbody>
              {dados.map((value) => (
                <tr
                  key={value.nSei}
                  onClick={() => {
                    mostrar(value.ref);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td>{value.nomeFantasia}</td>
                  <td>{value.nSei}</td>
                  <td>{value.lacre}</td>
                  <td>{value.tipo}</td>
                  <td>{value.auto}</td>
                  <td>{value.sala}</td>
                  <td>{value.coluna}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <div className="d-flex justify-content-between ">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn btn-primary hidden-print"
            table="table-to-xls"
            filename="Dados da consulta - COBA"
            sheet="Dados da consulta - COBA"
            buttonText="Exportar para Excel"
          />
        </div>
      </div>
    </>
  );
}
