import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "../src/pages/Login";
import Home from "../src/pages/Home";
import Carregando from "../src/pages/Carregando";
import FormEntidade from "../src/pages/FormEntidade";
import CreateProduct from "../src/pages/CreateProduct";
import Consulta from "../src/pages/Consulta";
import Results from "../src/pages/Results";
import ResultsEq from "../src/pages/ResultsEq";
import Alterar from "../src/pages/Alterar";
import AlterarEntidade from "../src/pages/AlterarEntidade";
import QrTeste from "../src/pages/QrTeste";
import * as firebase from "firebase/app";

export default class Routes extends Component {
  constructor() {
    super();
    this.state = {
      userId: null,
    };
  }

  componentDidMount = async () => {
    await firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        var uid = user.uid;
        this.setState({ userId: uid });
      } else {
        // User is signed out.
        this.setState({ userId: uid });
      }
    });
  };

  render() {
    if (this.state.userId === null) {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Carregando} />
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      );
    }
    if (this.state.userId === undefined) {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Redirect to="/login" />
          </Switch>
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/home" exact component={Home} />
            <Route path="/create-entidade" exact component={FormEntidade} />
            <Route path="/qr-teste" exact component={QrTeste} />
            <Route path="/create-product" exact component={CreateProduct} />
            <Route path="/dados-da-consulta" exact component={Consulta} />
            <Route path="/results" exact component={Results} />
            <Route path="/resultseq" exact component={ResultsEq} />
            <Route path="/alterar" exact component={Alterar} />
            <Route path="/alterarentidade" exact component={AlterarEntidade} />
            <Route path="/carregando" exact component={Carregando} />
            <Redirect to="/home" />
          </Switch>
        </BrowserRouter>
      );
    }
  }
}
