import React, { useState, useEffect } from "react";
import logo from "../../images/anatel-logo-1.png";
import * as firebase from "firebase/app";
import { Link, useHistory } from "react-router-dom";
import { Button, Spinner, Modal } from "react-bootstrap";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import QrReader from "react-qr-reader";

export default function Navbar() {
  let history = useHistory();

  const [newSala, setNewSala] = useState("");
  const [validNewSala, setValidNewSala] = useState("form-control ");
  const [disabledInput, setDisabledInput] = useState(true);
  const [diplaySmall, setDisplaySmall] = useState(
    "form-text text-muted d-none"
  );
  const [displayModal, setDisplayModal] = useState(
    "modal-dialog modal-dialog-centered"
  );

  const [displayModalQr, setModalDisplayQr] = useState(false);

  const [inputSearch, setInputSearch] = useState("");
  const [animation, setAnimation] = useState("");

  const [displayScan, setDisplayScan] = useState("d-none");

  const handleCloseQr = () => setModalDisplayQr(false);
  const handleShowQr = () => setModalDisplayQr(true);

  function handleScan(data) {
    if (data) {
      consultarQr(data);
      handleCloseQr();
    }
  }
  function handleError(err) {
    console.error(err);
  }

  useEffect(() => {
    if (window.screen.width < 640) {
      setDisplayScan("btn btn-primary");
    }
    setDisabledInput(false);
    setValidNewSala("form-control is-valid");
    setDisplaySmall("form-text text-muted d-none");
    firebase
      .firestore()
      .collection("salas")
      .where("nome", "==", newSala.toUpperCase().trim())
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          setValidNewSala("form-control is-invalid");
          setDisabledInput(true);
          setDisplaySmall("form-text text-muted");
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }, [newSala]);

  async function consultarEQ() {
    var arrayResults = [];
    await firebase
      .firestore()
      .collection("products")
      .where("consulta", "array-contains", inputSearch.toUpperCase().trim())
      .get()
      .then(async (querySnapshot) => {
        var querySize = querySnapshot.size;
        var quant = 0;

        if (querySize === 0) {
          toast.notify("Nenhum registro encontrado!");
          setInputSearch("");
          setAnimation("");
          history.push("/");
        } else {
          querySnapshot.forEach(async (doc) => {
            // doc.data() is never undefined for query doc snapshots
            var getData = doc.data();
            await firebase
              .firestore()
              .collection("entidades")
              .where("ref", "==", getData.ref)
              .get()
              .then(async (querySnapshot) => {
                querySnapshot.forEach((doceq) => {
                  // doc.data() is never undefined for query doc snapshots

                  getData.nomeFantasia = doceq.data().nomeFantasia;

                  arrayResults.push(getData);

                  quant++;

                  if (quant !== 0 && quant === querySize) {
                    localStorage.setItem(
                      "results",
                      JSON.stringify(arrayResults)
                    );
                    localStorage.setItem("quantResults", arrayResults.length);
                    setAnimation("");

                    history.push("/resultseq");
                  }
                  var um = 1;
                  console.log(um++);
                });
              })
              .catch(function (error) {
                console.log("Error getting documents: ", error);
              });
          });
        }
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  async function consultar(event) {
    event.preventDefault();
    setAnimation("grow");
    var arrayResults = [];
    await firebase
      .firestore()
      .collection("entidades")
      .where("consulta", "array-contains", inputSearch.toUpperCase().trim())
      .get()
      .then(async (querySnapshot) => {
        history.push("/carregando");
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          arrayResults.push(doc.data());
        });
        console.log(arrayResults);
        if (arrayResults.length === 0) {
          consultarEQ();
        } else {
          localStorage.setItem("results", JSON.stringify(arrayResults));
          localStorage.setItem("quantResults", arrayResults.length);
          setAnimation("");
          setInputSearch("");
          history.push("/results");
        }
      })
      .catch(function (error) {
        history.push("/");
        console.log("Error getting documents: ", error);
      });
  }
  async function consultarQr(data) {
    setAnimation("grow");
    var arrayResults = [];
    await firebase
      .firestore()
      .collection("entidades")
      .where("consulta", "array-contains", data.toUpperCase().trim())
      .get()
      .then(async (querySnapshot) => {
        history.push("/carregando");
        await querySnapshot.forEach(async (doc) => {
          // doc.data() is never undefined for query doc snapshots
          arrayResults.push(doc.data());
        });
        console.log(arrayResults);
        if (arrayResults.length === 0) {
          toast.notify("Nenhum registro encontrado!");
          history.push("/");
          setInputSearch("");
        } else {
          await localStorage.setItem("results", JSON.stringify(arrayResults));
          await localStorage.setItem("quantResults", arrayResults.length);
          setInputSearch("");
          setAnimation("");
          history.push("/results");
        }
      })
      .catch(function (error) {
        history.push("/");
        console.log("Error getting documents: ", error);
      });
  }
  async function cadastrarSala(event) {
    event.preventDefault();
    await firebase
      .firestore()
      .collection("salas")
      .where("nome", "==", newSala.toUpperCase().trim())
      .get()
      .then(async function (doc) {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc);
        if (doc.docs.length != 0) {
          toast.notify(
            `A sala "${newSala
              .toUpperCase()
              .trim()}" já existe. Escolha outro nome para continuar.`
          );
        } else {
          await firebase
            .firestore()
            .collection("salas")
            .add({
              nome: newSala.toUpperCase().trim(),
            })
            .then(() => {
              console.log("Document successfully written!");
              setNewSala("");
              toast.notify(
                `Sala "${newSala.toUpperCase().trim()}" cadastrada com sucesso!`
              );
              setDisplayModal("modal-dialog modal-dialog-centered d-none");
            })
            .catch((error) => {
              setNewSala("");
              setDisplayModal("modal-dialog modal-dialog-centered d-none");
            });
          setTimeout(function () {
            document.location.replace("/home");
          }, 1000);
        }
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  }

  async function logout() {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        console.log("Sign-out successful.");
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
        <Link to="/home">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              width="40"
              height="40"
              className="d-inline-block align-top"
              alt=""
            />{" "}
          </a>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#conteudoNavbarSuportado"
          aria-controls="conteudoNavbarSuportado"
          aria-expanded="false"
          aria-label="Alterna navegação"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="conteudoNavbarSuportado">
          <ul className="navbar-nav mr-auto">
            <Link to="/home">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Home<span className="sr-only">(página atual)</span>
                </a>
              </li>
            </Link>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Opções
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#cadastrarSalas"
                >
                  Cadastrar Salas
                </a>
              </div>
            </li>
            <li className="nav-item" onClick={logout}>
              <a className="nav-link" href="#">
                Sair
              </a>
            </li>
          </ul>

          <form onSubmit={consultar} className="form-inline my-2 my-lg-0">
            <input
              onChange={(event) => setInputSearch(event.target.value)}
              required
              value={inputSearch}
              className="form-control mr-sm-2"
              type="search"
              placeholder="Pesquisa rápida"
              aria-label="Pesquisar"
            />

            <Button variant="warning" type="submit" className="btn btn-primary">
              Pesquisar{" "}
              <Spinner
                as="span"
                animation={animation}
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>

            <button
              onClick={handleShowQr}
              type="button"
              class={displayScan}
              data-toggle="modal"
              data-target="#exampleModal"
              style={{ marginLeft: 10 }}
            >
              <i class="fas fa-camera"></i>
            </button>
          </form>
        </div>
      </nav>
      <div
        class="modal fade"
        id="cadastrarSalas"
        tabindex="-1"
        role="dialog"
        aria-labelledby="TituloModalCentralizado"
        aria-hidden="true"
      >
        <div class={displayModal} role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="TituloModalCentralizado">
                Cadastrar Sala
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Fechar"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form autoComplete="off" onSubmit={cadastrarSala}>
              <div class="modal-body">
                <label htmlFor="nomeSala">Dê um nome para a nova sala.</label>
                <input
                  required
                  onChange={(event) => setNewSala(event.target.value)}
                  type="text"
                  class={validNewSala}
                  id="nomeSala"
                  placeholder="Ex: A"
                />
                <small id="emailHelp" class={diplaySmall}>
                  Já existe uma sala com esse nome. Insira um nome diferente
                  para proseguir.
                </small>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
                <button
                  disabled={disabledInput ? "disabled" : ""}
                  type="submit"
                  class="btn btn-primary"
                >
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal show={displayModalQr} onHide={handleCloseQr}>
        <Modal.Header closeButton>
          <Modal.Title>Aponte para o QRCode.</Modal.Title>
        </Modal.Header>
        <div class="modal-dialog">
          <div class="modal-content">
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
