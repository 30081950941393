import React, { useEffect } from "react";
import NavBar from "../Navbar";
import "./styles.css";
import * as firebase from "firebase/app";



function Home({ history }) {
  async function userCurrent() {
    await firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        var uid = user.uid;
        console.log(uid);
      } else {
      }
    });
  }
  useEffect(() => {
    userCurrent();
  }, []);
  return (
    <>
      <NavBar />
      <div id="caixa-btn" className="col-md-5">
        <button
          onClick={() => {
            history.push("/create-entidade");
          }}
          type="button"
          className="btn btn-primary"
        >
          <span style={{ fontSize: "5em", color: "#fff" }}>
            <i className="fas fa-user-plus"></i>
          </span>
          <p>Adicionar uma nova entidade ou responsável.</p>
        </button>
        <button
          onClick={() => {
            history.push("/create-product");
          }}
          type="button"
          className="btn btn-success"
        >
          <span style={{ fontSize: "5em", color: "#fff" }}>
            <i className="fas fa-box"></i>
          </span>
          <p>Estocar equipamentos apreendido.</p>
        </button>
      </div>
    </>
  );
}

export default Home;
