import React, { Component } from "react";
import QrReader from "react-qr-reader";

export default class QrTeste extends Component {
  state = {
    result: "No result",
  };

  handleScan = (data) => {
    if (data) {
      this.setState({
        result: data,
      });
    }
  };
  handleError = (err) => {
    console.error(err);
  };
  render() {
    return (
      <div>
        <p>{this.state.result}</p>
      </div>
    );
  }
}
