import React, { Component } from 'react';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';


export default class Carregando extends Component {
    render() {
        return (
            <div className="container" style={{justifyContent: "center", alignItems: "center", height: "300px"}}>
                <Spinner size={100} color="#fff"/>
            </div>)
    }
}
