import React, { useEffect } from "react";
import "./App.css";
import Routes from "./routes";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import QRCode from "qrcode.react";
import android from "../src/images/download-android-app-png-4.png";

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
