import React, { useState } from "react";
import Navbar from "../Navbar";
import "./styles.css";
import * as firebase from "firebase/app";
import InputMask from "react-input-mask";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { Button, Spinner } from "react-bootstrap";

export default function FormEntidade({ history }) {
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [orgaoExpedidorRg, setorgaoExpedidorRg] = useState("");
  const [ufOrgaoExpedidorRg, setufOrgaoExpedidorRg] = useState("PI");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [vinculo, setVinculo] = useState("");
  const [endereco, setEndereco] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("PI");
  const [cep, setCep] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [check, setCheck] = useState(false);
  const [enderecoEntidade, setEnderecoEntidade] = useState("");
  const [cidadeEntidade, setCidadeEntidade] = useState("");
  const [estadoEntidade, setEstadoEntidade] = useState("PI");
  const [cepEntidade, setCepEntidade] = useState("");
  const [nSei, setNSei] = useState("");
  const [animation, setAnimation] = useState("");
  const [disabledInput, setDisabledInput] = useState(false);
  const [checkResponsavel, setCheckresponsável] = useState("d-none");
  const [pJudicial, setPJudicial] = useState("");
  const [refId, setRefId] = useState("teste");
  function checkEndereço() {
    if (check === false) {
      setCheck(true);
      setEndereco(enderecoEntidade);
      setCidade(cidadeEntidade);
      setEstado(estadoEntidade);
      setCep(cepEntidade);
    } else {
      setCheck(false);
      setEndereco("");
      setCidade("");
      setEstado("PI");
      setCep("");
    }
  }

  function enableResponsável() {
    if (checkResponsavel === "d-none") {
      setCheckresponsável(" ");
    } else {
      setCheckresponsável("d-none");
    }
  }

  async function separaNomes(
    ref,
    nSei,
    cpf,
    cnpj,
    cidadeEntidade,
    cidade,
    estado
  ) {
    var name = nomeCompleto;
    var fantasia = nomeFantasia;
    var social = razaoSocial;
    var arrayTotal = [ref, nSei, cpf, cnpj, cidadeEntidade, cidade, estado];
    for (var i = 0; i < name.length; i++) {
      var check = name
        .split(" ")
        .slice(0 + i, 1 + i)
        .join(" ");
      var checkNomeCompleto = name
        .split(" ")
        .slice(0, 1 + i)
        .join(" ");
      if (check !== "" || checkNomeCompleto !== name) {
        arrayTotal.push(check.toUpperCase());
        arrayTotal.push(checkNomeCompleto.toUpperCase());
      } else {
        break;
      }
    }
    for (var i = 0; i < fantasia.length; i++) {
      var check = fantasia
        .split(" ")
        .slice(0 + i, 1 + i)
        .join(" ");
      var checkNomeCompleto = fantasia
        .split(" ")
        .slice(0, 1 + i)
        .join(" ");
      if (check !== "" || checkNomeCompleto !== fantasia) {
        arrayTotal.push(check.toUpperCase());
        arrayTotal.push(checkNomeCompleto.toUpperCase());
      } else {
        break;
      }
    }
    for (var i = 0; i < social.length; i++) {
      var check = social
        .split(" ")
        .slice(0 + i, 1 + i)
        .join(" ");
      var checkNomeCompleto = social
        .split(" ")
        .slice(0, 1 + i)
        .join(" ");
      if (check !== "" || checkNomeCompleto !== social) {
        arrayTotal.push(check.toUpperCase());
        arrayTotal.push(checkNomeCompleto.toUpperCase());
      } else {
        break;
      }
    }
    perguntar(ref);
    var idUpdate = firebase.firestore().collection("entidades").doc(ref);

    // Set the "capital" field of the city 'DC'
    return idUpdate.update({
      consulta: arrayTotal,
      ref: ref,
    });
  }
  function perguntar(ref) {
    confirmAlert({
      title: "Salvo!",
      message: `ID da entidade: ${ref}. O ID é necessário para fazer o relacionamento entre os equipamentos e sua entidade responsável. Copiar para Área de Transferência?`,
      buttons: [
        {
          label: "Copiar ID",
          onClick: () => copiar(ref),
        },
        {
          label: "Não",
          onClick: () => history.push("/home"),
        },
      ],
    });
  }

  function copiar(ref) {
    const texto = ref;
    //Cria um elemento input (pode ser um textarea)
    let inputTest = document.createElement("input");
    inputTest.value = texto;
    //Anexa o elemento ao body
    document.body.appendChild(inputTest);
    //seleciona todo o texto do elemento
    inputTest.select();
    //executa o comando copy
    //aqui é feito o ato de copiar para a area de trabalho com base na seleção
    document.execCommand("copy");
    //remove o elemento
    document.body.removeChild(inputTest);
    toast.notify("ID copiado para Área de Transferência", {
      position: "top-right",
      tye: "success",
    });
  }
  async function cadastrarEntidade(event) {
    event.preventDefault();
    setDisabledInput(true);
    setAnimation("grow");
    await firebase
      .firestore()
      .collection("entidades")
      .add({
        nSei: nSei.trim(),
        nomeFantasia: nomeFantasia.toUpperCase().trim(),
        razaoSocial: razaoSocial.toUpperCase().trim(),
        vinculo: vinculo.toUpperCase().trim(),
        rg: rg.toUpperCase().trim(),
        orgaoExpedidorRg: orgaoExpedidorRg.toUpperCase().trim(),
        ufOrgaoExpedidorRg: ufOrgaoExpedidorRg.toUpperCase().trim(),
        cnpj: cnpj.trim(),
        enderecoEntidade: enderecoEntidade.toUpperCase().trim(),
        cidadeEntidade: cidadeEntidade.toUpperCase().trim(),
        estadoEntidade: estadoEntidade.toUpperCase().trim(),
        cepEntidade: cepEntidade.trim(),
        nomeCompleto: nomeCompleto.toUpperCase().trim(),
        cpf: cpf.trim(),
        telefone: telefone.trim(),
        celular: celular.trim(),
        endereco: endereco.toUpperCase().trim(),
        cidade: cidade.toUpperCase().trim(),
        estado: estado.toUpperCase().trim(),
        cep: cep.trim(),
        pJudicial: pJudicial.toUpperCase().trim(),
      })
      .then(async function (docRef) {
        console.log("Document written with ID: ", docRef.id);
        await separaNomes(
          docRef.id,
          nSei.trim(),
          cpf.trim(),
          cnpj.trim(),
          cidadeEntidade.toUpperCase().trim(),
          cidade.toUpperCase().trim(),
          estado.toUpperCase().trim()
        );
        // Set the "capital" field of the city 'DC'
        console.log("Document successfully updated!");
        history.push("/home");
        setAnimation("");
        setDisabledInput(true);
      })
      .catch(function (error) {
        console.log("Error adding document: ", error);
        setAnimation("");
        setDisabledInput(true);
      });
  }

  return (
    <>
      <Navbar />
      <div id="form-entidade">
        <form className="needs-validation" onSubmit={cadastrarEntidade}>
          <p>
            <strong>Dados do Processo</strong>
          </p>
          <div className="dropdown-divider"></div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="nsei">Nº do Processo SEI</label>
              <InputMask
                children={() => {
                  return (
                    <input pattern="[0-9./-]{20}" className="form-control" />
                  );
                }}
                disabled={disabledInput ? "disabled" : ""}
                value={nSei}
                onChange={(event) => setNSei(event.target.value)}
                mask="99999.999999/9999-99"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="pjudicial">Nº do Processo Judicial</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                onChange={(event) =>
                  setPJudicial(event.target.value.toUpperCase())
                }
                type="text"
                className="form-control"
                id="pjudicial"
                value={pJudicial}
              />
            </div>
          </div>
          <p>
            <strong>Dados da Entidade</strong>
          </p>
          <div class="dropdown-divider"></div>
          <div className="form-group">
            <label htmlFor="nome-fantasia">Nome Fantasia *</label>
            <input
              minLength={2}
              disabled={disabledInput ? "disabled" : ""}
              required
              onChange={(event) => setNomeFantasia(event.target.value)}
              type="text"
              className="form-control"
              id="nome-fantasia"
              placeholder="Nome Fantasia"
              value={nomeFantasia.toUpperCase()}
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="razao-social">Razão Social</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={razaoSocial}
                onChange={(event) => setRazaoSocial(event.target.value)}
                type="text"
                className="form-control"
                id="razao-social"
                placeholder="Razão Social"
                value={razaoSocial.toUpperCase()}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="cnpj">CNPJ</label>
              <InputMask
                disabled={disabledInput ? "disabled" : ""}
                alwaysShowMask={true}
                className="form-control"
                mask="99.999.999/9999-99"
                value={cnpj}
                onChange={(event) => setCnpj(event.target.value)}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="endereco-entidade">Endereço</label>
            <input
              disabled={disabledInput ? "disabled" : ""}
              value={enderecoEntidade}
              onChange={(event) => setEnderecoEntidade(event.target.value)}
              type="text"
              className="form-control"
              id="endereco-entidade"
              placeholder="Rua dos Bobos, nº 0"
              value={enderecoEntidade.toUpperCase()}
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="cidade-entidade">Cidade *</label>
              <input
                required
                disabled={disabledInput ? "disabled" : ""}
                value={cidadeEntidade}
                onChange={(event) => setCidadeEntidade(event.target.value)}
                type="text"
                className="form-control"
                id="cidade-entidade"
                value={cidadeEntidade.toUpperCase()}
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="estado-entidade">Estado</label>
              <select
                required
                disabled={disabledInput ? "disabled" : ""}
                value={estadoEntidade}
                onChange={(event) => setEstadoEntidade(event.target.value)}
                id="estado-entidade"
                className="form-control"
              >
                <option>AC</option>
                <option>AL</option>
                <option>AP</option>
                <option>AM</option>
                <option>BA</option>
                <option>CE</option>
                <option>DF</option>
                <option>ES</option>
                <option>GO</option>
                <option>MA</option>
                <option>MT</option>
                <option>MS</option>
                <option>MG</option>
                <option>PA</option>
                <option>PB</option>
                <option>PR</option>
                <option>PE</option>
                <option>PI</option>
                <option>RJ</option>
                <option>RN</option>
                <option>RS</option>
                <option>RO</option>
                <option>RR</option>
                <option>SC</option>
                <option>SP</option>
                <option>SE</option>
                <option>TO</option>
              </select>
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="cep-entidade">CEP</label>
              <InputMask
                disabled={disabledInput ? "disabled" : ""}
                value={cepEntidade}
                alwaysShowMask={true}
                className="form-control"
                mask="99999-999"
                onChange={(event) => setCepEntidade(event.target.value)}
              />
            </div>
          </div>
          <div className="form-check">
            <input
              disabled={disabledInput ? "disabled" : ""}
              onChange={enableResponsável}
              className="form-check-input"
              type="checkbox"
              id="responsavelCheck"
            />
            <label className="form-check-label" htmlFor="responsavelCheck">
              Clique aqui se desejar inserir os dados do responsável pela
              entidade acima.
            </label>
          </div>
          <div className="form-group">
            <small id="emailHelp" className="form-text text-muted">
              <strong>Obs: </strong>A entidade pode ser cadastrada mesmo sem os{" "}
              <strong>"Dados do Responsável"</strong>
            </small>
          </div>
          <div className={checkResponsavel}>
            <p>
              <strong>Dados do Responsável</strong>
            </p>
            <div class="dropdown-divider"></div>
            <div className="form-group">
              <label htmlFor="name">Nome Completo</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={nomeCompleto}
                onChange={(event) => setNomeCompleto(event.target.value)}
                type="text"
                className="form-control"
                id="name"
                placeholder="Nome Completo"
                value={nomeCompleto.toUpperCase()}
              />
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="cpf">CPF</label>
                <InputMask
                  disabled={disabledInput ? "disabled" : ""}
                  alwaysShowMask={true}
                  className="form-control"
                  mask="999.999.999-99"
                  value={cpf.toUpperCase()}
                  onChange={(event) => setCpf(event.target.value)}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="rg">RG</label>
                <InputMask
                  disabled={disabledInput ? "disabled" : ""}
                  maskChar=""
                  className="form-control"
                  value={rg.toUpperCase()}
                  onChange={(event) => setRg(event.target.value)}
                />
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="rg">Orgão Expedidor</label>
                <InputMask
                  disabled={disabledInput ? "disabled" : ""}
                  maskChar=""
                  className="form-control"
                  value={orgaoExpedidorRg.toUpperCase()}
                  onChange={(event) => setorgaoExpedidorRg(event.target.value)}
                />
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="inputEstado">UF</label>
                <select
                  disabled={disabledInput ? "disabled" : ""}
                  value={ufOrgaoExpedidorRg.toUpperCase()}
                  onChange={(event) =>
                    setufOrgaoExpedidorRg(event.target.value)
                  }
                  id="inputEstado"
                  className="form-control"
                >
                  <option>AC</option>
                  <option>AL</option>
                  <option>AP</option>
                  <option>AM</option>
                  <option>BA</option>
                  <option>CE</option>
                  <option>DF</option>
                  <option>ES</option>
                  <option>GO</option>
                  <option>MA</option>
                  <option>MT</option>
                  <option>MS</option>
                  <option>MG</option>
                  <option>PA</option>
                  <option>PB</option>
                  <option>PR</option>
                  <option>PE</option>
                  <option>PI</option>
                  <option>RJ</option>
                  <option>RN</option>
                  <option>RS</option>
                  <option>RO</option>
                  <option>RR</option>
                  <option>SC</option>
                  <option>SP</option>
                  <option>SE</option>
                  <option>TO</option>
                </select>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="telefone">Telefone</label>
                <InputMask
                  disabled={disabledInput ? "disabled" : ""}
                  alwaysShowMask={true}
                  className="form-control"
                  mask="(99) 9999-9999"
                  value={telefone}
                  onChange={(event) => setTelefone(event.target.value)}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="telefone">Celular</label>
                <InputMask
                  disabled={disabledInput ? "disabled" : ""}
                  alwaysShowMask={true}
                  className="form-control"
                  mask="(99) 99999-9999"
                  value={celular}
                  onChange={(event) => setCelular(event.target.value)}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="vinculo">Vínculo</label>
                <input
                  disabled={disabledInput ? "disabled" : ""}
                  value={vinculo}
                  onChange={(event) => setVinculo(event.target.value)}
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Ex: Locutor"
                  value={vinculo.toUpperCase()}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="form-check">
                <input
                  disabled={disabledInput ? "disabled" : ""}
                  onChange={checkEndereço}
                  className="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                />
                <label className="form-check-label" htmlFor="gridCheck">
                  Clique aqui caso o endereço do responsável seja o mesmo
                  endereço da entidade.
                </label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="endereco">Endereço</label>
              <input
                disabled={disabledInput ? "disabled" : ""}
                value={endereco}
                onChange={(event) => setEndereco(event.target.value)}
                type="text"
                className="form-control"
                id="endereco"
                placeholder="Rua dos Bobos, nº 0"
                value={endereco.toUpperCase()}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputCity">Cidade</label>
                <input
                  disabled={disabledInput ? "disabled" : ""}
                  value={cidade}
                  onChange={(event) => setCidade(event.target.value)}
                  type="text"
                  className="form-control"
                  id="inputCity"
                  value={cidade.toUpperCase()}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="inputEstado">Estado</label>
                <select
                  disabled={disabledInput ? "disabled" : ""}
                  value={estado}
                  onChange={(event) => setEstado(event.target.value)}
                  id="inputEstado"
                  className="form-control"
                >
                  <option>AC</option>
                  <option>AL</option>
                  <option>AP</option>
                  <option>AM</option>
                  <option>BA</option>
                  <option>CE</option>
                  <option>DF</option>
                  <option>ES</option>
                  <option>GO</option>
                  <option>MA</option>
                  <option>MT</option>
                  <option>MS</option>
                  <option>MG</option>
                  <option>PA</option>
                  <option>PB</option>
                  <option>PR</option>
                  <option>PE</option>
                  <option>PI</option>
                  <option>RJ</option>
                  <option>RN</option>
                  <option>RS</option>
                  <option>RO</option>
                  <option>RR</option>
                  <option>SC</option>
                  <option>SP</option>
                  <option>SE</option>
                  <option>TO</option>
                </select>
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="inputCEP">CEP</label>
                <InputMask
                  disabled={disabledInput ? "disabled" : ""}
                  alwaysShowMask={true}
                  className="form-control"
                  mask="99999-999"
                  value={cep}
                  onChange={(event) => setCep(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="btn-login">
            <Button variant="primary" type="submit" className="btn btn-primary">
              Cadastrar{" "}
              <Spinner
                as="span"
                animation={animation}
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          </div>
          <div></div>
        </form>
      </div>

      <p id="id"></p>
    </>
  );
}
